import { FC, useLayoutEffect, useRef } from 'react'
import DataModel from '../models/dataModel'
import styles from './css/PromoCsMoney.module.css'
import { useTypedSelector } from '../hooks/useTypedSelector'

const PromoCsMoney: FC = () => {
	const ref = useRef<HTMLDivElement>(null)

	useLayoutEffect(() => {
		ref.current.style.setProperty('padding', '0', 'important')
	}, [])

	const {
		current: currLang
	} = useTypedSelector(x => x.lang)

	if (currLang === 0) {

	}
	else {

	}

	const listOfBanner = currLang === 0 
		? [ 'banner1' ]
		: [ 'banner3_en' ]
	const randImage = Math.floor(Math.random() * listOfBanner.length)
    const bannerName = listOfBanner[randImage]
	
	const url = `https://cs.money/market/instant-sell/` + 
		`?utm_source=mediabuy&utm_medium=vvtable&utm_campaign=instantsell&utm_content=${bannerName}`

	return (
		<div className={styles.container} ref={ref}>
			<a href={url} target={'_blank'} rel={'nofollow'}>
				<img src={`Resources/promo/csmoney/${bannerName}.png`} />
			</a>
		</div>
	)
}

export default PromoCsMoney
